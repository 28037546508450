<template>
  <el-main class="rh-form">
    <form-fconttitrecreate :routes="fctRoutes" />
  </el-main>
</template>
<script>
import FormFconttitrecreate from "@/components/rh/formulaires/FormFconttitrecreate";

export default {
  components: { "form-fconttitrecreate": FormFconttitrecreate },
  computed: {
    fctRoutes() {
      return {
        toedit: {
          name: "fcontinuetitreedit",
          params: { idfctitre: this.$store.state.fctit.titreLastCreatedId },
        },
        tolist: { name: "fcontinuetitres" },
      };
    },
  },
};
</script>
